import React, { useState } from "react";
import "../addcategory/AddCategory.css";
import { useNavigate } from "react-router-dom";
import CategoryIcon from "../../Images/CategoryIcon.svg";
import { addCategory } from "../../api/lib/category";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../utils/helper";

const AddCategory = () => {
  const [userImage, setUserImage] = useState();
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const history = useNavigate();
  const [userData, setUserData] = useState({ title: "", image: "" });

  const readUserImage = (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", (ev) => {
      setUserImage(ev.target.result);
    });
    reader.readAsDataURL(imageFile);
  };

  const postUserData = async (event, data) => {
    event.preventDefault();
    validateFormError(data);
    try {
      const response = await addCategory(data);
      if (response) {
        notifySuccess("Category Added SuccessFully");
        history("/category");
      }
    } catch (error) {
      notifyError(error);
    }
  };

  const validateFormError = (userData) => {
    if (userData.title.trim().length < 3) {
      setTitleError("Title must be at least 3 characters long!");
    } else {
      setTitleError("");
    }

    if (!userData.image) {
      setImageError("Image must be included!");
    } else {
      setImageError("");
    }
  };

  return (
    <>
      <div className="add-category-window">
        <div className="add-category-heading">Add Category</div>
        <hr className="add-category" />
        <div className="add-category-body">
          <div
            className="icon-category"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <input
              type="file"
              id="select-image"
              accept="image/png, image/jpeg, image/svg"
              style={{ display: "none" }}
              onChange={(ev) => {
                setUserData({ ...userData, image: ev.target.files[0] });
                readUserImage(ev);
              }}
            />
            <img
              src={userImage ? userImage : CategoryIcon}
              alt=""
              className="category-img"
              onClick={() => document.querySelector("#select-image").click()}
            />
            <p style={{ color: "red" }}>{imageError}</p>
          </div>
          <div className="right-side-content">
            <div className="add-title">
              <form
                className="add-title-form"
                onSubmit={(ev) => postUserData(ev, userData)}
              >
                <label className="title-heading">Title:</label>
                <textarea
                  type="text"
                  get
                  placeholder="Add Title"
                  className="title-input"
                  onChange={(ev) =>
                    setUserData({ ...userData, title: ev.target.value })
                  }
                />
                <p style={{ color: "red" }}>{titleError}</p>
                <div className="btn-class">
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="save-btn"
                  >
                    Save
                  </button>
                  <button
                    className="cancel-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => history("/category")}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddCategory;
