import axiosClient from "../apiClient";

export const getLoginData = async (loginData) => {
  try {
    const response = await axiosClient.post(`admin/login`, loginData);
    return response;
  } catch (error) {
    console.error(error);
    throw error; // Propagate the error to the caller
  }
};

export const getAdminData = async () => {
  return await axiosClient.get(`admin/`);
};

export const changePassword = async (password) => {
  return await axiosClient
    .patch(`admin/change-password`, password)
    .catch((error) => {
      console.error(error);
    });
};
