import React, { useEffect, useRef, useState } from "react";
// import axios from "axios";
import "../addtemplate/AddTemplate.css";
import { getCategoryNames } from "../../api/lib/templates";
import { addTemplate } from "../../api/lib/templates";

import Frame from "../../Images/Frame.svg";
import UploadIcon from "../../Images/upload-icon.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../utils/helper";

const AddTemplate = () => {
  const history = useNavigate();
  const fileInputRef = useRef(null);
  const [templateData, setTemplateData] = useState({
    title: "",
    image: "",
    category: "",
    suitableFor: "",
    description: "",
    id: "",
  });
  const [imageError, setImageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [suitableError, setSuitableError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [categories, setCategories] = useState([]);
  const [templateImage, setTemplateImage] = useState();
  console.log(templateData);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/svg+xml"];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onload = function (ev) {
        document.getElementById("select-image").src = ev.target.result;
      };

      reader.readAsDataURL(file);
    } else {
      // Handle file type validation error
      alert("Please select a PNG, JPEG, or SVG file.");
    }
  };

  const readTemplateImage = (event) => {
    const imageFile = event.target.files[0];

    const reader = new FileReader();
    reader.addEventListener("load", (ev) => {
      setTemplateImage(ev.target.result);
    });
    if (imageFile) reader.readAsDataURL(imageFile);
  };

  const postTemplateData = async (event, templateData) => {
    console.log("Category Data ", templateData);
    templateValidate(templateData);
    event.preventDefault();
    try {
      const catresponse = await getCategoryNames();
      const categoriesData = catresponse?.data?.data;

      console.log(categoriesData);

      const { _id } = categoriesData.find(
        (element) => element.name === templateData.category
      );

      if (_id) {
        templateData.id = _id; // Add the ID to the template data
        const response = await addTemplate(templateData);
        console.log(response);
        if (response) {
          notifySuccess("Template Added SuccessFully");
          history("/video");
        } else {
          notifyError("Failed to Save the Template");
        }
      } else {
        alert("Category not Found ");
      }
      // Category name matches the retrieved ID
    } catch (error) {
      console.log("error", error);
    }
  };
  const templateValidate = (templateData) => {
    if (templateData.title.trim().length < 2) {
      setTitleError("Title must be 2 characters long!");
    } else {
      setTitleError("");
    }

    if (!templateData.image) {
      setImageError("Image must be included!");
    } else {
      setImageError("");
    }

    if (!templateData.category) {
      setCategoryError("Category is compulsory!");
    } else {
      setCategoryError("");
    }

    if (!templateData.suitableFor) {
      setSuitableError("Please Add the Age Group");
    } else {
      setSuitableError("");
    }

    if (!templateData.description) {
      setDescriptionError("Please add the Description");
    } else {
      setDescriptionError("");
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategoryNames();
        const categoriesData = data?.data.data;
        console.log(categoriesData, "categories-response");
        if (Array.isArray(categoriesData)) {
          setCategories(categoriesData);
        } else {
          console.log("Invalid category data:", categoriesData);
        }
      } catch (error) {
        console.log("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <div className="addtemplate-window">
        <div className="addtemplate-heading">Add Template</div>
        <hr className="addtemplate-hr" />
        <div className="addtemplate-body">
          <div className="addtemplate-upload">
            <img
              src={templateImage ? templateImage : Frame}
              alt=""
              className="addtemplate-imgupload"
              style={{ cursor: "pointer" }}
              id="#select-image"
              onClick={() => document.querySelector("#select-image").click()}
            />
            <button
              className="addtemplate-upload-btn"
              style={{ cursor: "pointer" }}
              onClick={handleButtonClick}
            >
              <div className="upload-text">Upload Template</div>
              <img src={UploadIcon} alt="" className="upload-icon" />
            </button>
            <input
              type="file"
              style={{ display: "none" }}
              accept=".png, .jpeg, .jpg, .svg"
              id="select-image"
              ref={fileInputRef}
              onChange={(ev) => {
                setTemplateData({ ...templateData, image: ev.target.files[0] });
                handleFileSelect(ev);
                readTemplateImage(ev);
              }}
            />
            <p style={{ color: "red" }}>{imageError}</p>
          </div>
          <div className="addtemplate-form-side">
            <form
              onSubmit={(ev) => postTemplateData(ev, templateData)}
              className="addtemplate-form"
            >
              <div className="form-wrap">
                <label htmlFor="" className="addtemplate-title">
                  Title
                </label>

                <input
                  type="text"
                  placeholder="Add Title"
                  className="addtemplate-titleinput"
                  onChange={(ev) =>
                    setTemplateData({ ...templateData, title: ev.target.value })
                  }
                />
                <p style={{ color: "red" }}>{titleError}</p>
              </div>
              <div className="form-wrap">
                <label htmlFor="" className="addtemplate-category">
                  Category
                </label>
                <select
                  className="addtemplate-categoryinput"
                  onChange={(ev) =>
                    setTemplateData({
                      ...templateData,
                      category: ev.target.value,
                    })
                  }
                >
                  <option value="">Select a category</option>
                  {console.log(categories, "categories-data")}
                  {categories.map((category) => (
                    <option value={category.name} key={category._id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <p style={{ color: "red" }}>{categoryError}</p>
              </div>
              <div className="form-wrap">
                <label htmlFor="" className="addtemplate-suitaiblity">
                  Suitable For
                </label>

                <input
                  type="text"
                  placeholder="0-5 Year Old"
                  className="addtemplate-suitable-input"
                  onChange={(ev) =>
                    setTemplateData({
                      ...templateData,
                      suitableFor: ev.target.value,
                    })
                  }
                />
                <p style={{ color: "red" }}>{suitableError}</p>
              </div>
              <div className="form-wrap">
                <label htmlFor="" className="addtemplate-description">
                  Description
                </label>

                <textarea
                  type="text"
                  placeholder="Add Description"
                  className="addtemplate-description-inp"
                  onChange={(ev) =>
                    setTemplateData({
                      ...templateData,
                      description: ev.target.value,
                    })
                  }
                />
                <p style={{ color: "red" }}>{descriptionError}</p>
              </div>
              <div className="addtemplate-btn">
                <button
                  type="submit"
                  style={{ cursor: "pointer" }}
                  className="addtemplate-savebtn"
                >
                  Save
                </button>
                <button
                  onClick={() => history("/video")}
                  style={{ cursor: "pointer" }}
                  className="addtemplate-cancelbtn"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default AddTemplate;
