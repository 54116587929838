import "./BoxWrapper.css";

const BoxWrapper = (props) => {
  return (
    <div className="row  users-wrapper">
      <div className="col md-4 wrapper-info">
        <h6 className="name-container">{props.data.name}</h6>

        <p className="value-container"> {props.data.value}</p>
        <h6 className="growth-container">
          {/* {props.data.arrow}+3% {props.data.growth} */}
        </h6>
      </div>
      <div className="svg-container">{props.data.svg}</div>
    </div>
  );
};
export default BoxWrapper;
