import React from "react";
import "../changePasswordModal.css";
import "./Navigation.css";

// Components
import SideBar from "../SidePanels/Midpanel/SideBar";
import TopPanel from "../SidePanels/TopPanel/TopPanel";
import BottomPanel from "../SidePanels/BottomPanel/BottomPanel";

function Navigation() {
  return (
    <div className="navigation">
      <TopPanel />
      <hr />
      <SideBar />
      <hr />
      <BottomPanel />
    </div>
  );
}

export default Navigation;
