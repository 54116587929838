import React, { useState } from "react";

import "../users/Users.css";
import UserStatus from "../../Images/user-status.svg";
import UserStatusFalse from "../../Images/UserStatusFalse.svg";
import UserView from "../../Images/user-view-icon.svg";
import DeleteUser from "../../Images/delete-icon.svg";
import UserImage from "../../Images/usericon.svg";

import { getProfileListing } from "../../api/lib/user";
import { getUserDetail } from "../../api/lib/user";
import { updateUserStatus } from "../../api/lib/user";
import { findUsers } from "../../api/lib/user";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteUserModal from "./DeleteUserModal";
import { useContext } from "react";
import DataContext from "../../context/DataContext";
import UserDetailDisplayModal from "./UserDetailDisplayModal";
import { notifyError, notifySuccess } from "../../utils/helper";

const Users = ({ searchQuery }) => {
  const [current, setCurrent] = useState(1);
  const [meta, setMeta] = useState({});
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { setUserDetail } = useContext(DataContext);

  const openModal = () => {
    setShowUserModal(true);
  };

  const openDeleteModal = (userId) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  };

  const getUserInfo = async (userId) => {
    const { data: detail } = await getUserDetail(userId);
    setUserDetail(detail?.data);
    openModal();
  };

  const getUserData = async (pageNumber, query = "") => {
    let apiResponse;

    if (query !== "") {
      apiResponse = await findUsers(pageNumber, query); // searching the user
    } else {
      apiResponse = await getProfileListing(pageNumber); // getting the user listing
    }

    const { data } = apiResponse;
    const userData = data.data || [];
    const meta = data.meta;

    setMeta(meta);

    setSearchResults(userData);
  };

  const changeUserStatus = async (userID) => {
    const response = await updateUserStatus(userID);
    console.log(response, "status change response");
    if (response.status === 200) {
      // Update the status in the searchResults array
      notifySuccess(response?.data?.message);
      setSearchResults((prevResults) =>
        prevResults.map((result) =>
          result._id === userID ? { ...result, status: !result.status } : result
        )
      );
    } else {
      notifyError("Cannot change the User Status");
    }
  };

  useEffect(() => {
    let getDataId;
    if (searchQuery !== "") {
      getDataId = setTimeout(() => {
        getUserData(current, searchQuery);
      }, 500);
    } else getUserData(current, searchQuery);

    return () => clearTimeout(getDataId);
  }, [current, searchQuery]);

  return (
    <>
      <DeleteUserModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        userId={selectedUserId}
        setSearchResults={setSearchResults}
      />
      <UserDetailDisplayModal
        showUserModal={showUserModal}
        setShowUserModal={setShowUserModal}
      />
      <div className="users-window">
        <div className="window-head">
          <p className="tot-results">Showing {meta?.total_records} results</p>
        </div>
        <div className="users-tab">
          <div className="user-tab-main">
            <div className="user-tab-main-left-wrap">
              <p className="users-tabcolumn">Users</p>
            </div>
            <div className="user-tab-main-right-wrap">
              <p className="users-tabstatus">Status</p>
              <p className="users-tabview">View</p>
              <p className="users-tabdelete">Delete</p>
            </div>
          </div>
          <hr className="user-tab-hr" />

          <div className="user-tab-content">
            {searchResults.map((data, index) => (
              <div className="user1" key={index}>
                <div className="user-content-left">
                  {/* <input type="checkbox" className="users-checkbox" /> */}
                  <img
                    src={
                      data?.profilePic?.url ? data?.profilePic?.url : UserImage
                    }
                    alt=""
                    className="user-view-img"
                  />
                  <p className="username">{data.name}</p>
                </div>
                {/* {data?.status && <div className="user-content-right">true</div>} */}
                <div className="user-content-right">
                  <img
                    src={data.status ? UserStatus : UserStatusFalse}
                    alt=""
                    className="user-status"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeUserStatus(data._id)}
                  />
                  <img
                    src={UserView}
                    style={{ cursor: "pointer" }}
                    alt=""
                    className="user-status"
                    onClick={() => getUserInfo(data._id)}
                  />
                  <div className="delete-section-user">
                    <img
                      src={DeleteUser}
                      style={{ cursor: "pointer" }}
                      alt=""
                      className="user-status"
                      onClick={() => openDeleteModal(data._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current - 1)}
              disabled={current === 1} // Disable button if current page is 1
            >
              Previous
            </button>
            <p className="total-results">
              Showing Page {meta?.current_page} of {meta?.total_pages}
            </p>
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current + 1)}
              disabled={current === meta?.total_pages} // Disable button if no results on the current page
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Users;
