import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as NeemoLogo } from "../../Images/NeemoSVGlogo.svg";
import "./Loginbox.css";
import { getLoginData } from "../../api/lib/admin";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginBox = () => {
  const navigate = useNavigate(); // used to navigate to route
  const passwordRef = useRef(null); // Ref for the password input field
  const [passwordVisible, setPasswordVisible] = useState(false);

  const initialValues = {
    email: "",
    password: "",
    role: "A",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [data, setData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // To trigger the Login API
  const login = async () => {
    try {
      const response = await getLoginData(formValues);
      const data = response.data;
      console.log(data, "login-response");
      const accessToken = data.data.accessToken;
      console.log(accessToken, "token-by");
      localStorage.setItem("accessToken", accessToken);
      console.log(localStorage.getItem("accessToken"));
      console.log(response?.data?.code);
      if (response?.data?.code === 200 && response?.data?.success === true) {
        notifySuccess("logged in SuccessFully");
        navigate("/dashboard");
      } else {
        console.log(response?.data?.message);
        alert("Wrong Details");
      }
    } catch (error) {
      // alert("Invalid Login Details");
      notifyError("Invalid Login Details");
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsSubmit(true);
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setData([...data, formValues]);
      login();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (e.target.name === "email") {
        // If Enter key is pressed in the email input, focus on the password input
        passwordRef.current.focus();
      } else {
        // If Enter key is pressed in the password input, submit the form
        handleSubmit(e);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    console.log(values, "values");

    if (!values.email) {
      errors.email = "Enter your Email";
    } else if (!values.email.includes("@")) {
      errors.email = "Not a Valid Email";
    } else if (!values.password) {
      errors.password = "Enter your Password";
    } else if (values.password.length < 4) {
      errors.password = "Your Password length must be greater than 4";
    } else {
      localStorage.setItem("user", JSON.stringify([...data, formValues]));
    }

    return errors;
  };

  useEffect(() => {
    console.log(formErrors, "error");

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues, "useeffect");
    }
  }, [formValues, formErrors, isSubmit]);

  return (
    <>
      <ToastContainer />
      <div className="Wrapper">
        {/* First Part */}
        <div className="loginbox-image">
          <NeemoLogo />
        </div>
        {/* Second Section  */}
        <div className="login-details">
          {/* Title */}
          <h3 className="login-title">Login your account</h3>
          {/* Login Boxes */}
          <div className="login-info">
            <input
              type="text"
              name="email"
              placeholder="Email"
              className={`login-input ${formErrors.email ? "error" : ""}`}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={formValues.email}
            />
            {formErrors.email && (
              <span className="error-text" style={{ color: "red" }}>
                {formErrors.email}
              </span>
            )}

            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              placeholder="Password"
              className="login-input"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={formValues.password}
              ref={passwordRef}
            />
            <div className="show-password-toggle">
              <input
                type="checkbox"
                className="toggle-password-btn"
                onClick={() => setPasswordVisible(!passwordVisible)}
              ></input>
              <span className="password-toggle-title">Show Password</span>
            </div>

            {/* <div className="pwd-info">
            <span className="remember-me">
              <input type="checkbox" className="remember-me_checkbox" />
              <div className="remember-link">Remember Me</div>
            </span>

            <h6 className="forgot-pwd">Forgot Password?</h6>
          </div> */}
          </div>
          <button className="login-btn" onClick={handleSubmit}>
            Login
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginBox;
