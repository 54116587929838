import React, { useEffect } from "react";
import { useState } from "react";
import ReactModal from "react-modal";
import { useRef } from "react";
import CloseButton from "../../Images/CloseButton.svg";
import UploadIcon from "../../Images/upload-icon.svg";
import {
  changeTemplate,
  getCategoryNames,
  getTemplateDetail,
} from "../../api/lib/templates";
import { notifySuccess, notifyError } from "../../utils/helper";

const TemplateEditModal = ({
  templateId,
  editModal,
  setEditModal,
  setUpdateStatus,
}) => {
  const [templateImage, setTemplateImage] = useState();
  const [templateData, setTemplateData] = useState({
    title: "",
    image: "",
    category: "",
    suitableFor: "",
    description: "",
    categoryId: "",
    id: "",
  });
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [suitableError, setSuitableError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [prevTemp, setPrevTemp] = useState({});
  console.log(prevTemp, "previous template data");
  const fileInputRef = useRef(null);
  const [categories, setCategories] = useState([]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const clearFileInput = () => {
    setTemplateImage(""); // Clear the image tag
    const fileInput = document.querySelector("#select-image");
    fileInput.value = ""; // Clear the file input value
  };
  const removeFile = () => {
    clearFileInput();
    setEditModal(false);
  };
  const readTemplateImage = (event) => {
    const imageFile = event.target.files[0];
    setTemplateData({ ...templateData, image: imageFile });

    const reader = new FileReader();
    reader.addEventListener("load", (ev) => {
      setTemplateImage(ev.target.result);
    });
    if (imageFile) reader.readAsDataURL(imageFile);
  };

  const patchtemplateData = async (ev, templateData) => {
    console.log("template Data", templateData);
    ev.preventDefault();
    patchValidate(templateData);
    try {
      const catresponse = await getCategoryNames();
      const categoriesData = catresponse?.data?.data;
      console.log(categoriesData);

      const { _id } = categoriesData.find(
        (element) => element.name === templateData.category
      );

      if (_id) {
        templateData.categoryId = _id; // Add the category id to the template data
        const response = await changeTemplate(templateData);
        if (response) {
          notifySuccess("template Saved Successfully");
          setEditModal(false);
          setUpdateStatus(true);
        } else {
          notifyError("Failed to save the template");
        }
      } else {
        notifyError("Category not found");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const patchValidate = (templateData) => {
    if (!templateData.image) {
      setImageError("Image must be there!");
    } else {
      setImageError("");
    }

    if (!templateData.title) {
      setTitleError("Please add the Title!");
    } else {
      setTitleError("");
    }

    if (!templateData.category) {
      setCategoryError("Please add the Category!");
    } else {
      setCategoryError("");
    }

    if (!templateData.suitableFor) {
      setSuitableError("Please add the suitable age group!");
    } else {
      setSuitableError("");
    }

    if (!templateData.description) {
      setDescriptionError("Please add the description!");
    } else {
      setDescriptionError("");
    }
  };

  useEffect(() => {
    if (templateId) {
      getData(templateId);
    }
  }, [templateId]);
  const getData = async (templateId) => {
    const { data: getprevTemp } = await getTemplateDetail(templateId);
    setPrevTemp(getprevTemp);
    setTemplateData({ ...templateData, id: getprevTemp?.data._id });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategoryNames();
        const categoriesData = data?.data.data;
        console.log(categoriesData, "categories-response");
        if (Array.isArray(categoriesData)) {
          setCategories(categoriesData);
        } else {
          console.log("Invalid category data:", categoriesData);
        }
      } catch (error) {
        console.log("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  return (
    <ReactModal
      className="edit-template-modal"
      isOpen={editModal}
      onRequestClose={() => removeFile()}
      ariaHideApp={false}
    >
      <div className="edit-template-modal-body">
        <div className="edit-template-modal-header">
          <div className="edit-template-modal-heading">Edit Template</div>
          <img
            src={CloseButton}
            alt=""
            className="edit-template-modal-closebutton"
            style={{ cursor: "pointer" }}
            onClick={() => removeFile()}
          />
        </div>
        <hr className="edit-template-modal-hr" />
        <div className="edit-modal-content-body" key={prevTemp?.data?._id}>
          <div className="edit-modal-leftwrap">
            <div className="edit-modal-image-upload-box">
              <input
                type="file"
                id="select-image"
                accept="image/png, image/jpeg, image/svg"
                style={{ display: "none" }}
                getCategoryData
                ref={fileInputRef}
                onChange={(ev) => {
                  setTemplateData({
                    ...templateData,
                    image: ev.target.files[0],
                  });
                  readTemplateImage(ev);
                }}
              />
              <img
                src={templateImage || prevTemp?.data?.videoTemplate?.url || ""}
                alt=""
                className="edit-modal-image"
                id="#select-image"
                onClick={() => document.querySelector("#select-image").click()}
              />
              <p style={{ color: "red" }}>{imageError}</p>
              <button
                className="template-upload-btn"
                onClick={handleButtonClick}
              >
                <div className="upload-text">Upload </div>
                <img src={UploadIcon} alt="" className="upload-icon" />
              </button>
            </div>
          </div>
          <div className="edit-modal-rightwrap">
            <form
              action=""
              onSubmit={(ev) => patchtemplateData(ev, templateData)}
              className="edit-template-modal-form"
            >
              <div className="edit-template-modal-wrap">
                <div className="edit-template-modal-labelwrapper">
                  <label htmlFor="" className="edit-template-heading">
                    Title:
                  </label>
                  <input
                    type="text"
                    placeholder={prevTemp?.data?.name}
                    className="edit-template-input"
                    onChange={(ev) =>
                      setTemplateData({
                        ...templateData,
                        title: ev.target.value,
                      })
                    }
                  />
                  <p style={{ color: "red" }}>{titleError}</p>
                </div>
                <div className="edit-template-modal-labelwrapper">
                  <label htmlFor="" className="edit-template-heading">
                    Category
                  </label>
                  <select
                    className="edit-template-input"
                    style={{ cursor: "pointer" }}
                    onChange={(ev) =>
                      setTemplateData({
                        ...templateData,
                        category: ev.target.value,
                      })
                    }
                  >
                    <option value="" style={{ color: "#979799" }}>
                      Select a category
                    </option>
                    {console.log(categories, "categories-data")}
                    {categories.map((category) => (
                      <option
                        style={{ cursor: "pointer" }}
                        value={category.name}
                        key={category._id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <p style={{ color: "red" }}>{categoryError}</p>
                </div>
                <div className="edit-template-modal-labelwrapper">
                  <label htmlFor="" className="edit-template-heading">
                    Suitable For:
                  </label>
                  <input
                    type="text"
                    placeholder={prevTemp?.data?.suitableFor}
                    className="edit-template-input"
                    onChange={(ev) =>
                      setTemplateData({
                        ...templateData,
                        suitableFor: ev.target.value,
                      })
                    }
                  />
                  <p style={{ color: "red" }}>{suitableError}</p>
                </div>
                <div className="edit-template-modal-labelwrapper">
                  <label htmlFor="" className="edit-template-heading">
                    Description:
                  </label>
                  <input
                    type="text"
                    placeholder={prevTemp?.data?.description}
                    className="edit-template-input"
                    onChange={(ev) =>
                      setTemplateData({
                        ...templateData,
                        description: ev.target.value,
                      })
                    }
                  />
                  <p style={{ color: "red" }}>{descriptionError}</p>
                </div>
              </div>
              <div className="button-wrap">
                <button
                  type="submit"
                  className="edit-template-modal-save-btn"
                  style={{ cursor: "pointer" }}
                >
                  Save
                </button>
                <button
                  onClick={() => removeFile()}
                  className="edit-template-modal-cancel-btn"
                  style={{ cursor: "pointer" }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};
export default TemplateEditModal;
