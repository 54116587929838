import ReactModal from "react-modal";
import CloseButton from "../../Images/CloseButton.svg";
import { notifyError, notifySuccess } from "../../utils/helper";
import { deleteTemplate, getTemplateListing } from "../../api/lib/templates";

const TemplateDeleteModal = ({
  showDeleteModal,
  setShowDeleteModal,
  templateId,
  setSearchResults,
  setUpdateStatus,
}) => {
  const handleTemplateDelete = async () => {
    const response = await deleteTemplate(templateId);

    if (response?.data?.code === 200) {
      notifySuccess("User Deleted Successfully");
      setShowDeleteModal(false);
      setUpdateStatus(true);

      const templateDataResponse = await getTemplateListing(); // getting the user listing
      setSearchResults(templateDataResponse?.data?.data);
    } else {
      notifyError(response?.data?.message);
    }
  };

  return (
    <ReactModal
      className="delete-template-modal"
      isOpen={showDeleteModal}
      onRequestClose={() => setShowDeleteModal(false)}
      ariaHideApp={false}
    >
      <div className="delete-template-body">
        <div className="delete-template-header">
          <div className="delete-template-heading">
            Are you sure to delete this Template
          </div>
          <img
            src={CloseButton}
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteModal(false)}
            alt=""
            className="delete-template-closeButton"
          />
        </div>
        <hr className="delete-templatemodal-hr" />
        <div className="delete-template-content">
          <div className="delete-template-button-section">
            <button
              className="delete-template-button"
              style={{ cursor: "pointer" }}
              onClick={() => handleTemplateDelete(templateId)}
            >
              Yes
            </button>
            <button
              className="delete-template-button"
              style={{ cursor: "pointer" }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default TemplateDeleteModal;
