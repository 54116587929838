import axiosClient from "../apiClient";

export const getProfileListing = async (pageNumber = 1) => {
  return axiosClient.get(`admin/userListing?page=${pageNumber}&limit=7`);
};

export const getUserDetail = async (userId) => {
  return axiosClient.get(`admin/user/${userId}`);
};

export const updateUserStatus = async (userId) => {
  return axiosClient.get(`admin/userStatusChange/${userId}`);
};

export const findUsers = async (pageNumber, query) => {
  return axiosClient.get(
    `admin/searchUser?searchKey=${query}&page=${pageNumber}&limit=7`
  );
};

export const deleteUser = async (userId) => {
  return axiosClient.delete(`admin/user/${userId}`);
};
