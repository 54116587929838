import "../dashboard/Dashboard.css";

//components imports

import UserDisplay from "../../components/UserDisplay/UserDisplay";
import BoxWrapper from "../../components/BoxWrapper/BoxWrapper";
// import Graph2 from "../../components/Graph2";

//svg imports
import { ReactComponent as UserCardSvg } from "../../Images/UserCardSvg.svg";
import { ReactComponent as CategoryCardSvg } from "../../Images/CategoryCardSvg.svg";
import { ReactComponent as TemplateIcon } from "../../Images/TemplateIcon.svg";
import { ReactComponent as Arrow } from "../../Images/Arrow.svg";

import Graph3 from "../../components/Graph3";
import { getDashboardData } from "../../api/lib/dashboard";
import { useContext, useEffect, useState } from "react";
import { getAdminData } from "../../api/lib/admin";
import DataContext from "../../context/DataContext";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [currentDate, setCurrentDate] = useState("");

  const { setAdminData } = useContext(DataContext);

  useEffect(() => {
    (async () => {
      const response = await getAdminData();
      console.log({ response });

      if (response?.status === 200) {
        setAdminData(response?.data?.data);
      }
    })();
  }, [setAdminData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardData();
        setDashboardData(response.data); // Assuming the response contains the data you need
      } catch (error) {
        console.log("Error fetching dashboard data:", error);
      }
    };

    const currentDate = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = currentDate.toLocaleDateString(undefined, options);
    setCurrentDate(formattedDate);

    fetchData();
  }, []);

  console.log(dashboardData, "dashboard-data");

  if (dashboardData === null) {
    // Render loading state or return null
    return <p>Loading...</p>;
  }

  const dashData = dashboardData.data;

  const WrapperInfo = [
    {
      name: "Users",
      value: dashData.userCount,
      growth: "compare with last month",
      svg: <UserCardSvg />,
      arrow: <Arrow />,
    },
    {
      name: "Categories",
      value: dashData.categoriesCount,
      growth: "compare with last month",
      svg: <CategoryCardSvg />,
      arrow: <Arrow />,
    },
    {
      name: "Templates",
      value: dashData.templateCount,
      growth: "compare with last month",
      svg: <TemplateIcon />,
      arrow: <Arrow />,
    },
  ];

  return (
    <>
      <div className="dashboard-content">
        <div className="userinfo-and-date">
          <UserDisplay />
          <div className="current-date"> {currentDate} </div>
        </div>

        <div className=" container d-flex wrapper-box">
          {WrapperInfo.map((data) => (
            <BoxWrapper data={data} />
          ))}
        </div>
        <div className="recharts-container">
          <span className="chart-heading">Growth Chart</span>
          <Graph3 data={dashData.graphData} />
        </div>
        {/* <Graph2 /> */}
      </div>
    </>
  );
};

export default Dashboard;
