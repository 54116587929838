import DataContext from "../../context/DataContext";
import { useContext } from "react";
import ReactModal from "react-modal";
import CloseButton from "../../Images/CloseButton.svg";

const TemplateDetailDisplayModal = ({
  showTemplateModal,
  setShowTemplateModal,
}) => {
  const { templateDetail } = useContext(DataContext);

  return (
    <ReactModal
      className="view-template-modal"
      isOpen={showTemplateModal}
      onRequestClose={() => setShowTemplateModal(false)}
      ariaHideApp={false}
    >
      <div className="view-template-body" key={templateDetail._id}>
        <div className="view-template-header">
          <div className="view-template-heading">Template Details</div>
          <img
            src={CloseButton}
            alt=""
            style={{ cursor: "pointer" }}
            className="view-template-closebutton"
            onClick={() => setShowTemplateModal(false)}
          />
        </div>
        <hr className="view-template-hr" />
        <div className="view-template-content-body">
          <div className="view-template-leftwrap">
            <img
              src={templateDetail?.videoTemplate?.url}
              alt=""
              className="view-template-img"
            />
          </div>
          <div className="view-template-rightwrap">
            <div className="view-template-right-content">
              <div className="view-template-wrap">
                <div className="view-template-modal-heading">Title</div>

                <div className="view-template-title-content">
                  {templateDetail.name}
                </div>
              </div>
              <div className="view-template-wrap">
                <div className="view-template-modal-heading">Suitable For</div>
                <div className="view-template-suitable-content">
                  {templateDetail.suitableFor}
                </div>
              </div>
              <div className="view-template-wrap">
                <div className="view-template-modal-heading">Description</div>
                <div className="view-description-content">
                  {templateDetail.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default TemplateDetailDisplayModal;
