import React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Graph3 = (graph) => {
  console.log(graph, "graph-data");
  const data = graph.data.map(({ m, c }) => ({
    year: m, // Assuming the month names are the year values
    value: c,
  }));

  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#049f8f" stopOpacity={0.4} />
            <stop offset="95%" stopColor="#ffffff" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#049f8f"
          fill="url(#gradient)"
          fillOpacity={0.8}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph3;
