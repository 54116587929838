import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { notifySuccess } from "../../utils/helper";
import CloseButton from "../../Images/CloseButton.svg";
import { changeCategory, getCategoryDetail } from "../../api/lib/category";

const CategoryEditModal = ({
  categoryId,
  setUpdateStatus,
  editModal,
  setEditModal,
}) => {
  const [titleError, setTitleError] = useState("");
  const [imageError, setImageError] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryData, setCategoryData] = useState({
    title: "",
    image: "",
    id: "",
  });
  const [prevCategory, setPrevCategory] = useState({});
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    if (categoryId) {
      getData(categoryId);
    }
  }, [categoryId]);

  const getData = async (categoryId) => {
    try {
      const response = await getCategoryDetail(categoryId);
      const getprevCategory = response.data;
      setPrevCategory(getprevCategory);
      setCategoryData({
        ...categoryData,
        title: getprevCategory.data.name,
        id: getprevCategory.data._id,
      });
    } catch (error) {
      console.log("Error fetching category details:", error);
    }
  };

  const clearFileInput = () => {
    setCategoryImage("");
    const fileInput = document.querySelector("#select-image");
    fileInput.value = "";
  };

  const removeHistory = () => {
    clearFileInput();
    setEditModal(false);
  };

  const readCategoryImage = (event) => {
    const imageFile = event.target.files[0];
    setCategoryData({ ...categoryData, image: imageFile });

    const reader = new FileReader();
    reader.addEventListener("load", (ev) => {
      setCategoryImage(ev.target.result);
    });

    if (imageFile) reader.readAsDataURL(imageFile);
  };

  const validateFormError = (categoryData) => {
    if (categoryData.title.trim().length < 3) {
      setTitleError("Title must be at least 3 characters long!");
    } else {
      setTitleError("");
    }

    if (!categoryData.image) {
      setImageError("Image must be included!");
    } else {
      setImageError("");
    }
  };

  const patchCategoryData = async (ev, data) => {
    ev.preventDefault();
    validateFormError(data);

    try {
      const formData = new FormData();
      formData.append("name", data.title);
      formData.append("categoryIcon", data.image);

      const response = await changeCategory(formData, data.id);
      if (response.data.code === 200) {
        notifySuccess("Patched successfully");
        setEditModal(false);
        setUpdateStatus(true);
      }
    } catch (error) {
      console.log("Error patching category:", error);
    }
  };

  useEffect(() => {
    console.log(prevCategory, "Updated prevCategory");
  }, [prevCategory]);

  return (
    <ReactModal
      className="edit-category-modal"
      isOpen={editModal}
      onRequestClose={() => removeHistory()}
      ariaHideApp={false}
    >
      <div className="category-edit-body">
        <div className="category-edit-header">
          <span className="category-edit-heading">Edit Category</span>
          <img
            src={CloseButton}
            style={{ cursor: "pointer" }}
            alt=""
            className="edit-category-closebutton"
            onClick={() => removeHistory()}
          />
        </div>
        <hr className="edit-category-hr" />
        <div className="category-edit-content">
          <form
            action=""
            onSubmit={(ev) => patchCategoryData(ev, categoryData)}
            className="edit-category-form"
          >
            <div className="category-image-input">
              <input
                type="file"
                id="select-image"
                accept="image/png, image/jpeg, image/svg"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(ev) => {
                  setCategoryData({
                    ...categoryData,
                    image: ev.target.files[0],
                  });
                  readCategoryImage(ev);
                }}
              />
              <img
                src={
                  categoryImage || prevCategory.data?.categoryIcon?.url || ""
                }
                alt=""
                className="edit-category-image-input"
                onClick={() => document.querySelector("#select-image").click()}
              />
              <p style={{ color: "red" }}>{imageError}</p>
            </div>
            <div className="edit-category-form-wrapper">
              <label htmlFor="" className="edit-category-name">
                Category Name
              </label>
              <input
                type="text"
                className="edit-category-input"
                value={categoryData.title}
                onChange={(ev) =>
                  setCategoryData({ ...categoryData, title: ev.target.value })
                }
              />
              <p style={{ color: "red" }}>{titleError}</p>
            </div>
            <div className="edit-category-button-wrap">
              <button
                type="submit"
                style={{ cursor: "pointer" }}
                className="save-edit-category"
              >
                Save
              </button>
              <button
                onClick={() => removeHistory()}
                className="cancel-edit-category"
                style={{ cursor: "pointer" }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  );
};

export default CategoryEditModal;
