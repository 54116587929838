import React from "react";
import "./SearchBar.css";
import Searchicon from "../../Images/Search-icon.svg";

function SearchBar({ setSearchQuery }) {
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="search-bar">
      <input
        type="search"
        accept={Searchicon}
        placeholder="Search"
        className="search-panel"
        onChange={handleSearch}
      />
    </div>
  );
}

export default SearchBar;
