import { toast } from "react-toastify";

import { toastStyleObject } from "../constants";

const getAuthorizationToken = () => {
  let token = "";
  if (localStorage) {
    token = localStorage.getItem("accessToken");
  }
  console.log(localStorage.getItem("accessToken"));
  console.log(token, "token stored in the variable");
  return token;
};

export const notifySuccess = (message) =>
  toast.success(<p style={{ fontSize: 16 }}>{message}</p>, toastStyleObject);

export const notifyError = (message) =>
  toast.error(<p style={{ fontSize: 16 }}>{message}</p>, toastStyleObject);

export { getAuthorizationToken };
