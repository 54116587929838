import { createContext, useEffect, useState } from "react";
import { getAdminData } from "../api/lib/admin";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [userDetail, setUserDetail] = useState({});
  const [templateDetail, setTemplateDetail] = useState({});
  const [categoryDetail, setCategoryDetail] = useState({});
  const [adminData, setAdminData] = useState({});

  return (
    <DataContext.Provider
      value={{
        userDetail,
        setUserDetail,
        templateDetail,
        setTemplateDetail,
        categoryDetail,
        setCategoryDetail,
        adminData,
        setAdminData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
