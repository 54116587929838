import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

//cssimports
import "../../createProfileModal.css";
import "../../Navigation/Navigation.css";

//svg imports
import Logo from "../../../Images/Logo.svg";
import UserImg from "../../../Images/Ellipse 70.svg";
import UserSvg from "../../../Images/user-pic.svg";
import CloseButton from "../../../Images/CloseButton.svg";
import { Link } from "react-router-dom";

// import DataContext from "../../../context/DataContext";
import { getAdminData } from "../../../api/lib/admin";
const TopPanel = () => {
  const [getAdmin, setAdmin] = useState({});

  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    // console.log("Open Model");
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    (async () => {
      const response = await getAdminData();
      console.log(response);

      if (response?.status === 200) {
        setAdmin(response?.data?.data);
      }
    })();
  }, []);

  return (
    <div className="top-panel" mt-6>
      <Link to={"/dashboard"}>
        <img src={Logo} alt="" className="top-logo" />
      </Link>
      <ReactModal
        className="create-profile-modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // appElement={el}
        ariaHideApp={false}
      >
        <div className="create-profilemodal-title">
          <span className="create-profile-head"> Create Profile </span>
          <img
            src={CloseButton}
            style={{ cursor: "pointer" }}
            alt=""
            className="close-button-modal"
            onClick={() => closeModal()}
          />
        </div>
        <hr className="profile-modal-hr" />
        <div className="create-profile-modal-body">
          <div className="profile-image-modal">
            <img src={UserSvg} alt="" className="profile-pic-modal" />
          </div>
          <div className="edit-profile-div">
            <form action="" className="edit-profile">
              <label htmlFor="" className="profile-form-modal-name">
                Name
              </label>
              <br />
              <input
                type="text"
                placeholder="Your Name"
                className="profile-form-modal-nameinput"
              />
              <label htmlFor="" className="profile-form-modal-email">
                Email
              </label>
              <br />
              <input
                type="text"
                placeholder="Your Email"
                className="profile-form-modal-emailinput"
              />
              <button className="create-profile-form-modal-btn">Create</button>
            </form>
          </div>
        </div>
      </ReactModal>
      {/* {console.log("adminlisting", adminData)} */}
      {getAdmin ? (
        <div className="user-profile-display">
          {console.log(getAdmin, "--------name------")}
          <div className="user-image-display">
            <img
              src={UserImg}
              alt=""
              style={{ cursor: "pointer" }}
              className="user-img"
              onClick={() => openModal()}
            />
          </div>
          <div
            className="user-info"
            style={{ cursor: "pointer" }}
            onClick={() => openModal()}
          >
            <span className="user-name" style={{ cursor: "pointer" }}>
              {getAdmin?.name}
            </span>
            <span className="admin-acc" style={{ cursor: "pointer" }}>
              {getAdmin?.role === "A" ? "Admin Account" : ""}
            </span>
            <span className="email" style={{ cursor: "pointer" }}>
              {getAdmin?.email}
            </span>
          </div>
        </div>
      ) : (
        <p>No admin data available</p>
      )}
    </div>
  );
};
export default TopPanel;
