import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpPage from "./pages/CreateProfile/SignUp";
import Login from "./pages/welcome/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Navigation from "./components/Navigation/Navigation";
import Users from "./pages/users/Users";
import Category from "./pages/categories/Category";
import Templates from "./pages/templates/Templates";
import AddCategory from "./pages/addcategory/AddCategory";
import AddTemplate from "./pages/addtemplate/AddTemplate";

import SearchBar from "./components/searchBar/SearchBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataProvider } from "./context/DataContext";

function App() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <BrowserRouter>
      <DataProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          {/* <Route path="/dashboard" element={<Dashboard />}></Route> */}
          {/* <Route path="/" element={<ProtectedRoute />}> */}
          <Route
            path="/dashboard"
            element={
              <ComponentWithNavigation setSearchQuery={setSearchQuery}>
                <Dashboard searchQuery={searchQuery} />{" "}
              </ComponentWithNavigation>
            }
          ></Route>
          {/* </Route> */}
          <Route path="/navigation" element={<Navigation />}></Route>
          {/* <Route path="/users" element={<Users />}></Route> */}
          <Route
            path="/category"
            element={
              <ComponentWithNavigation setSearchQuery={setSearchQuery}>
                <Category searchQuery={searchQuery} />{" "}
              </ComponentWithNavigation>
            }
          ></Route>
          <Route
            path="/video"
            element={
              <ComponentWithNavigation setSearchQuery={setSearchQuery}>
                <Templates searchQuery={searchQuery} />
              </ComponentWithNavigation>
            }
          ></Route>
          <Route
            path="/add-category"
            element={
              <ComponentWithNavigation>
                <AddCategory />
              </ComponentWithNavigation>
            }
          />
          <Route path="/signUp" element={<SignUpPage />}></Route>
          <Route
            path="/add-template"
            element={
              <ComponentWithNavigation>
                <AddTemplate />
              </ComponentWithNavigation>
            }
          ></Route>
          <Route
            path="/users"
            element={
              <ComponentWithNavigation setSearchQuery={setSearchQuery}>
                {" "}
                {/* Pass setSearchQuery */}
                <Users searchQuery={searchQuery} />
              </ComponentWithNavigation>
            }
          ></Route>
        </Routes>
      </DataProvider>
    </BrowserRouter>
  );
}

const ComponentWithNavigation = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useNavigate();

  const setSearchQuery = props.setSearchQuery;

  console.log(props);

  useEffect(() => {
    const user = localStorage.getItem("user");
    // ToastContainer;
    setIsAuthenticated(user !== null);
    if (!user) {
      history("/");
    }
  }, [isAuthenticated, history]);

  return (
    <>
      <div className="fluid">
        <div className="main">
          <div className="nav-container">
            <Navigation />
          </div>
          <div className="content-container">
            <ToastContainer />
            <SearchBar setSearchQuery={setSearchQuery} />
            <div className="children">{props.children}</div>
            <div className="bottom-box"></div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default App;
