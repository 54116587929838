import React, { useState } from "react";
import "../../changePasswordModal.css";
import ReactModal from "react-modal";
import "../../Navigation/Navigation.css";
//svg imports
import SettingIcon from "../../../Images/settings-icon.svg";
import SupportIcon from "../../../Images/support-icon.svg";
import LogoutIcon from "../../../Images/logout-icon.svg";
import CloseButton from "../../../Images/CloseButton.svg";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../api/lib/admin";

const Modal = ({ modal2IsOpen, set2IsOpen }) => {
  function closeSettingModal() {
    set2IsOpen(false);
  }

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  console.log(passwordValues, "password-values");
  const navigate = useNavigate();

  const updatePassword = async (ev) => {
    ev.preventDefault();
    // console.log("entered ");
    const response = await changePassword(passwordValues);
    if (response.code === 200) {
      navigate("/dashboard");
      alert("Password Changed Successfully");
    } else {
      alert("Could not Change Password");
    }
  };

  return (
    <ReactModal
      // overlayClassName="modaloverlay"
      className="change-password-modal2"
      isOpen={modal2IsOpen}
      onRequestClose={closeSettingModal}
      // appElement={el}
      ariaHideApp={false}
    >
      <div className="change-password-modal-title">
        {" "}
        <span className="change-pwd-heading">Change Password</span>
        <img
          style={{ cursor: "pointer" }}
          src={CloseButton}
          alt=""
          className="closebutton-password-modal"
          onClick={() => closeSettingModal()}
        />
      </div>
      <hr className="change-password-modal-hr" />
      <div className="change-password-modal-body">
        <form
          action=""
          onSubmit={(ev) => updatePassword(ev)}
          className="change-password-form"
        >
          <label htmlFor="" className="old-password-modal">
            Old Password
          </label>

          <input
            type="text"
            placeholder="Old Password"
            className="old-password-input"
            onChange={(e) =>
              setPasswordValues({
                ...passwordValues,
                oldPassword: e.target.value,
              })
            }
          />

          <label htmlFor="" className="new-password-form">
            New Password
          </label>

          <input
            type="text"
            placeholder="New Password"
            className="new-password-input"
            onChange={(e) =>
              setPasswordValues({
                ...passwordValues,
                newPassword: e.target.value,
              })
            }
          />
          <label htmlFor="" className="confirm-password-form">
            Confirm Password
          </label>

          <input
            type="text"
            placeholder="Confirm Password"
            className="confirm-password-input"
            onChange={(e) =>
              setPasswordValues({
                ...passwordValues,
                confirmPassword: e.target.value,
              })
            }
          />
          <button type="submit" className="change-password-modal-btn">
            Update Password
          </button>
        </form>
      </div>
    </ReactModal>
  );
};

const BottomPanel = () => {
  const [modal2IsOpen, set2IsOpen] = React.useState(false);
  const [logout, setLogout] = useState(false);

  const navigate = useNavigate();

  const openlogout = () => {
    setLogout(true);
  };
  const closeLogout = () => {
    setLogout(false);
  };
  const openSettingModal = () => {
    set2IsOpen(true);
  };
  const handleLogout = () => {
    localStorage.clear("user");
    navigate("/");
  };

  return (
    <div className="bottom-panel">
      <ReactModal
        className="user-delete-modal"
        isOpen={logout}
        onRequestClose={closeLogout}
        ariaHideApp={false}
      >
        {" "}
        <div className="delete-user-modal-body">
          <div className="delete-user-modal-header">
            <div className="delete-user-modal-heading">
              Are you Sure you want to Logout?
            </div>
            <div className="user-delete-modal-close">
              <img
                src={CloseButton}
                alt=""
                onClick={() => closeLogout()}
                style={{ cursor: "pointer" }}
                className="user-delete-modal-close-icon"
              />
            </div>
          </div>
          <hr className="user-delete-modal-hr" />
          <div className="user-delete-modal-content">
            <div className="user-delete-modal-button-section">
              <button
                className="user-deletemodal-button"
                style={{ cursor: "pointer" }}
                onClick={handleLogout}
              >
                Yes
              </button>

              <button
                className="user-deletemodal-button"
                style={{ cursor: "pointer" }}
                onClick={closeLogout}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <Modal modal2IsOpen={modal2IsOpen} set2IsOpen={set2IsOpen} />
      <div className="gen-head">GENERAL</div>
      <div
        className="setting-pane"
        style={{ cursor: "pointer" }}
        onClick={openSettingModal}
      >
        <div className="settings-icon-div">
          <img src={SettingIcon} alt="" className="setting-icon" />
        </div>
        <div className="setting-head">Settings</div>
      </div>
      <div className="support-pane" style={{ cursor: "pointer" }}>
        <div className="support-icon-div">
          <img src={SupportIcon} alt="" className="support-icon" />
        </div>
        <div className="support-head">Support</div>
      </div>
      <div
        className="logout-box"
        style={{ cursor: "pointer" }}
        onClick={openlogout}
      >
        <div className="logout-icon-div">
          <img src={LogoutIcon} alt="" className="logout-icon" />
        </div>
        <button className="logout-btn" style={{ cursor: "pointer" }}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default BottomPanel;
