import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as DashIcon } from "../../../Images/dashboard-icon.svg";
import { ReactComponent as UserIcon } from "../../../Images/user-icon.svg";
import { ReactComponent as Category } from "../../../Images/Category.svg";
import { ReactComponent as VideoIcon } from "../../../Images/video-icon.svg";

const SideBar = () => {
  const [activeTab, setActiveTab] = useState();
  let location = useLocation();

  const midPanelData = [
    {
      id: 1,
      name: "Dashboard",
      svg: <DashIcon className="tab-icon" />,
      link: "/dashboard",
    },
    {
      id: 2,
      name: "Users",
      svg: <UserIcon className="tab-icon" />,
      link: "/users",
    },
    {
      id: 3,
      name: "Categories",
      svg: <Category className="tab-icon" />,
      link: "/category",
    },
    {
      id: 4,
      name: "Video",
      svg: <VideoIcon className="tab-icon" />,
      link: "/video",
    },
  ];

  useEffect(() => {
    if (location.pathname === "/add-category") {
      setActiveTab("/category");
    } else if (location.pathname === "/add-template") {
      setActiveTab("/video");
    } else {
      setActiveTab(location.pathname);
    }
  }, [location]);

  console.log(activeTab);

  return (
    <>
      <div className="mid-panel">
        {midPanelData.map((data) => (
          <Link
            to={data.link}
            onClick={() => setActiveTab(data.link)}
            className={` ${activeTab === data.link && "active-tab"} dash-small`}
          >
            {data.svg}
            <span className={`${activeTab === data.link} dash-head`}>
              {data.name}
            </span>
          </Link>
        ))}
      </div>
    </>
  );
};

export default SideBar;
