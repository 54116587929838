import axiosClient from "../apiClient";

export const getTemplateListing = async (pageNumber) => {
  return await axiosClient.get(`video-template?page=${pageNumber}&limit=7`);
};

export const getTemplateDetail = async (templateId) => {
  return await axiosClient.get(`video-template/${templateId}`);
};

export const findTemplate = async (pageNumber, query) => {
  return await axiosClient.get(
    `admin/searchTemplate?searchKey=${query}&page=${pageNumber}&limit=7`
  );
};

export const deleteTemplate = async (templateId) => {
  return await axiosClient.delete(`video-template/${templateId}`);
};

export const addTemplate = async (data) => {
  const formData = new FormData();
  formData.append("name", data?.title);
  formData.append("videoTemplate", data?.image);
  formData.append("suitableFor", data?.suitableFor);
  formData.append("description", data?.description);
  formData.append("categoryId", data?.id);

  return await axiosClient
    .post(`video-template`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      console.error(error);
    });
};

export const changeTemplate = async (templateData) => {
  const formData = new FormData();
  formData.append("name", templateData?.title);
  formData.append("videoTemplate", templateData.image);
  formData.append("suitableFor", templateData?.suitableFor);
  formData.append("description", templateData?.description);
  formData.append("categoryId", templateData?.categoryId);

  return await axiosClient
    .patch(`video-template/${templateData.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getCategoryNames = async () => {
  return await axiosClient(`categories?page=1&limit=20`);
};
