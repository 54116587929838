import DataContext from "../../context/DataContext";
import { useContext } from "react";
import ReactModal from "react-modal";
import CloseButton from "../../Images/CloseButton.svg";
import UserImage from "../../Images/usericon.svg";

const UserDetailDisplayModal = ({ showUserModal, setShowUserModal }) => {
  const { userDetail } = useContext(DataContext);

  return (
    <ReactModal
      className="user-detail-modal"
      isOpen={showUserModal}
      onRequestClose={() => setShowUserModal(false)}
      ariaHideApp={false}
    >
      <div className="user-detail-body" key={userDetail._id}>
        <div className="user-detail-header">
          <span className="user-detail-heading">Profile Details</span>
          <img
            src={CloseButton}
            style={{ cursor: "pointer" }}
            alt=""
            className="profile-close-button"
            onClick={() => setShowUserModal(false)}
          />
        </div>
        <hr className="profile-detail-hr" />
        <div className="profile-detail-content">
          <div className="profile-left">
            <div className="profile-image">
              <img
                src={
                  userDetail?.profilePic?.url
                    ? userDetail?.profilePic?.url
                    : UserImage
                }
                alt=""
                className="profile-wrapper"
              />
            </div>
            <div className="profile-name">{userDetail.name}</div>
          </div>
          <div className="profile-vertical-line"></div>
          <div className="profile-right">
            <div className="profile-info-wrapper">
              <div className="right-profile-wrapper">
                <div className="info-title-profile">Email</div>
                <div className="info-display-profile">{userDetail.email}</div>
              </div>
              <div className="right-profile-wrapper">
                <div className="info-title-profile">Gender</div>
                <div className="info-display-profile">{userDetail.gender}</div>
              </div>
              <div className="right-profile-wrapper">
                <div className="info-title-profile">Date Of Birth</div>
                <div className="info-display-profile">{userDetail.dob}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default UserDetailDisplayModal;
