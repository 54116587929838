import React, { useContext, useState } from "react";

import "../categories/Category.css";
import CategoryView from "../../Images/user-view-icon.svg";
import DeleteUser from "../../Images/delete-icon.svg";
import Edit from "../../Images/Edit.svg";

import { Link } from "react-router-dom";
import { findCategory, getCategoryListing } from "../../api/lib/category";
import { getCategoryDetail } from "../../api/lib/category";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CategoryDetailModal from "./CategoryDetailModal";
import CategoryDeleteModal from "./CategoryDeleteModal";
import DataContext from "../../context/DataContext";
import CategoryEditModal from "./CategoryEditModal";
const Category = ({ searchQuery }) => {
  const [categoryListing, setCategoryListing] = useState([]);
  const [current, setCurrent] = useState(1);
  const [meta, setMeta] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCatId, setSelectedCatId] = useState("");
  const [editCategoryId, setEditCategoryId] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false); // New state variable for update status

  const { categoryDetail, setCategoryDetail } = useContext(DataContext);

  const openModal = () => {
    setShowModal(true);
  };

  const openEditModal = (categoryId) => {
    setEditModal((prev) => ({ ...prev, editModal: true }));
    setEditCategoryId(categoryId);
  };

  const openDeleteModal = (catID) => {
    console.log(catID);
    setShowDeleteModal(true);
    setSelectedCatId(catID);
  };

  const getCategoryData = async (pageNumber, query = "") => {
    let apiResponse;
    console.log(query, "query passing in the category");
    if (query !== "") {
      apiResponse = await findCategory(pageNumber, query);
    } else {
      apiResponse = await getCategoryListing(pageNumber);
    }
    const { data } = apiResponse;
    console.log("categorydata", data);
    const categoryData = data.data || [];
    const meta = data.meta;
    setMeta(meta);
    setCategoryListing(categoryData);
    setSearchResults(categoryData);
  };

  const getCategoryInfo = async (id) => {
    const { data: detail } = await getCategoryDetail(id);
    console.log(detail);
    setCategoryDetail(detail?.data);
    openModal();
  };

  useEffect(() => {
    let getDataId;
    if (searchQuery !== "") {
      getDataId = setTimeout(() => {
        getCategoryData(current, searchQuery);
      }, 500);
    } else getCategoryData(current, searchQuery);

    return () => clearTimeout(getDataId);
  }, [current, searchQuery]);

  useEffect(() => {
    if (updateStatus) {
      getCategoryData(current, searchQuery);
      setUpdateStatus(false); // Reset the update status
    }
  }, [current, searchQuery, updateStatus]);
  return (
    <>
      {categoryDetail && (
        <CategoryDetailModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {/* {editModal ? editCategoryModal() : null} */}
      <CategoryDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        categoryId={selectedCatId}
        searchResults={setSearchResults}
        setUpdateStatus={setUpdateStatus}
      />
      <CategoryEditModal
        categoryId={editCategoryId}
        setUpdateStatus={setUpdateStatus}
        editModal={editModal}
        setEditModal={setEditModal}
      />

      <div className="category-window">
        <div className="category-head">
          <p className="tot-results">Showing {meta?.total_records} results</p>

          <Link to="/add-category">
            {
              <button
                className="category-add-btn"
                style={{ cursor: "pointer" }}
              >
                Add
              </button>
            }
          </Link>
        </div>
        <div className="category-tab">
          <div className="category-tab-main">
            <div className="category-tab-main-left-wrap">
              {/* <input
                type="checkbox"
                className="category-checkbox"
                onChange={handleMainCheckboxChange}
              /> */}
              <p className="title">Title</p>
            </div>
            <div className="category-tab-main-right-wrap">
              <p className="category-view">View</p>
              <p className="edit">Edit</p>
              <p className="delete">Delete</p>
            </div>
          </div>
          <hr className="category-tab-hr" />
          <div className="category-tab-content">
            {console.log("categorylisting", categoryListing)}
            {searchResults.map((data, index) => (
              <div className="category1" key={index}>
                <div className="category-content-left">
                  {/* <input type="checkbox" className="category-checkbox" /> */}
                  <p className="category-name">{data.name ?? ""}</p>
                </div>
                <div className="category-content-right">
                  <img
                    style={{ cursor: "pointer" }}
                    src={CategoryView}
                    alt=""
                    className="category-content-view"
                    onClick={() => getCategoryInfo(data._id)}
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    src={Edit}
                    alt=""
                    className="category-edit-icon"
                    onClick={() => openEditModal(data._id)}
                  />
                  <div className="delete-section-category">
                    <img
                      style={{ cursor: "pointer" }}
                      src={DeleteUser}
                      alt=""
                      className="deletecategory"
                      onClick={() => openDeleteModal(data._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current - 1)}
              disabled={current === 1}
            >
              Previous
            </button>
            <p className="total-results">
              Showing Page {meta?.current_page} of {meta?.total_pages}
            </p>
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current + 1)}
              disabled={current === meta?.total_pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Category;
