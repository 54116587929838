import "../welcome/Login.css";
import LoginBox from "../../components/login/LoginBox";
import login from "../../Images/login.svg";

const Login = () => {
  return (
    <div className="welcome-page">
      <div className="left-container">
        <img src={login} alt="" className="login-img" />
        <div className="over-text">
          Welcome To <span style={{ fontWeight: "700" }}>NEEMO</span>
        </div>
      </div>
      <div className="right-container">
        <LoginBox />
      </div>
    </div>
  );
};

export default Login;
