import "./UserDisplay.css";
import { useContext } from "react";

import DataContext from "../../context/DataContext";

const UserDisplay = () => {
  const { adminData } = useContext(DataContext);
  return (
    <div className="user-display">
      {console.log(adminData, "adminDatain the dashboard")}
      {adminData && (
        <p style={{ color: "#343434", fontWeight: "600", fontSize: "2.2rem" }}>
          Hello {adminData?.name}{" "}
          <span style={{ color: "#959595" }}>welcome back!</span>
        </p>
      )}
    </div>
  );
};

export default UserDisplay;
