import DataContext from "../../context/DataContext";
import { useContext } from "react";
import ReactModal from "react-modal";
import CloseButton from "../../Images/CloseButton.svg";

const CategoryDetailModal = ({ showModal, setShowModal }) => {
  const { categoryDetail } = useContext(DataContext);

  return (
    <ReactModal
      className="View-Category-Modal"
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      ariaHideApp={false}
    >
      <div className="category-detail-body">
        <div className="category-detail-header">
          <span className="category-detail-heading">Category Detail</span>
          <img
            src={CloseButton}
            alt=""
            className="view-category-closebutton"
            style={{ cursor: "pointer" }}
            onClick={() => setShowModal(false)}
          />
        </div>
        <hr className="view-category-hr" />
        <div className="category-detail-content-body">
          <div className="categoryview-img">
            <img
              src={categoryDetail?.categoryIcon?.url}
              alt=""
              className="category-view-modal-img"
            />
          </div>
          <div className="categoryview-details">
            <div className="categorydetail-wrapper">
              <span className="Category-heading">Name</span>
              <span className="category-title">{categoryDetail.name}</span>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default CategoryDetailModal;
