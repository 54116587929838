import axiosClient from "../apiClient";

export const getCategoryListing = async (pageNumber) => {
  return await axiosClient.get(`categories?page=${pageNumber}&limit=7`);
};

export const getCategoryDetail = async (categoryId) => {
  return await axiosClient.get(`categories/${categoryId}`);
};

export const findCategory = async (pageNumber, query) => {
  return await axiosClient.get(
    `admin/searchCategory?searchKey=${query}&page=${pageNumber}&limit=7`
  );
};

export const deleteCategory = async (categoryId) => {
  return await axiosClient.delete(`categories/${categoryId}`);
};

export const addCategory = async (data) => {
  const formData = new FormData();
  formData.append("name", data?.title);
  formData.append("categoryIcon", data?.image);

  return await axiosClient
    .post(`categories`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      console.error(error);
    });
};

export const changeCategory = async (data, id) => {
  return await axiosClient.patch(`categories/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
