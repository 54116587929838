import React from "react";
import "./WelcomePage.css";
import SingingBoy from "../../Images/Singing-boy.svg";
import UserPic from "../../Images/user-pic.svg";
function SignUpPage() {
  return (
    <>
      <div className="profile-page">
        <div className="profile-welcome">
          <img src={SingingBoy} alt="" className="welcome-img" />
          <div className="profile-page-heading">
            Welcome to <span className="neemo">Neemo</span>
          </div>
          <div className="profile-page-title">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
            molestiae mollitia, ratione soluta deserunt velit earum at.
            Perferendis blanditiis iste eveniet fuga asperiores, aliquid
            aspernatur quo alias, qui inventore laudantium?
          </div>
        </div>
        <div className="profile-create">
          <div className="upper-panel">
            <div className="profile-create-title">Create Profile</div>
            <img src={UserPic} alt="" className="user-profile-pic" />
          </div>
          <div className="lower-panel">
            <form className="info-form">
              <label className="name-title"> Name </label>

              <br />
              <input
                type="text"
                placeholder="Enter Name"
                className="name-input"
              />
              <br />

              <label className="email-title"> Email</label>
              <br />
              <input
                type="text"
                placeholder="Your Email"
                className="email-input"
              />
              <br />
              <label className="dob-title"> Date of Birth</label>
              <br />
              <input
                type="text"
                placeholder="Date Of Birth"
                className="dob-input"
              />
              <br />

              <label className="gender-title">Gender</label>
              <br />
              <div className="gender-declaration">
                <input
                  type="radio"
                  placeholder="male"
                  className="gender-male"
                />
                <span className="gender-decl">Male</span>
                <input
                  type="radio"
                  placeholder="female"
                  className="gender-female"
                />
                <span className="gender-decl"> Female</span>
              </div>
              <button className="create-profile-btn">Create</button>
            </form>
          </div>
        </div>
      </div>
      <div />
    </>
  );
}
export default SignUpPage;
