import ReactModal from "react-modal";
import CloseButton from "../../Images/CloseButton.svg";
import { deleteUser, getProfileListing } from "../../api/lib/user";
import { notifyError, notifySuccess } from "../../utils/helper";

const DeleteUserModal = ({
  showDeleteModal,
  setShowDeleteModal,
  userId,
  setSearchResults,
}) => {
  const handleDeleteUser = async () => {
    const response = await deleteUser(userId);

    if (response?.data?.code === 200) {
      notifySuccess("User Deleted Successfully");
      setShowDeleteModal(false);

      const userDataResponse = await getProfileListing(); // getting the user listing
      setSearchResults(userDataResponse?.data?.data);
    } else {
      notifyError(response?.data?.message);
    }
  };

  return (
    <ReactModal
      className="user-delete-modal"
      isOpen={showDeleteModal}
      onRequestClose={() => setShowDeleteModal(false)}
      ariaHideApp={false}
    >
      <div className="delete-user-modal-body">
        <div className="delete-user-modal-header">
          <div className="delete-user-modal-heading">
            Are you Sure to delete this User?
          </div>
          <div className="user-delete-modal-close">
            <img
              src={CloseButton}
              alt=""
              onClick={() => setShowDeleteModal(false)}
              style={{ cursor: "pointer" }}
              className="user-delete-modal-close-icon"
            />
          </div>
        </div>
        <hr className="user-delete-modal-hr" />
        <div className="user-delete-modal-content">
          <div className="user-delete-modal-button-section">
            <button
              className="user-deletemodal-button"
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteUser(userId)}
            >
              Yes
            </button>

            <button
              className="user-deletemodal-button"
              style={{ cursor: "pointer" }}
              onClick={() => setShowDeleteModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default DeleteUserModal;
