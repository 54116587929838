import React, { useContext, useEffect, useState } from "react";

import "./Templates.css";
import CategoryView from "../../Images/user-view-icon.svg";
import DeleteUser from "../../Images/delete-icon.svg";
import Edit from "../../Images/Edit.svg";

import { Link } from "react-router-dom";

import { getTemplateListing } from "../../api/lib/templates";
import { findTemplate } from "../../api/lib/templates";
import { getTemplateDetail } from "../../api/lib/templates";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DataContext from "../../context/DataContext";
import TemplateDetailDisplayModal from "./TemplateDetailDisplayModal";
import TemplateDeleteModal from "./TemplateDeleteModal";
import TemplateEditModal from "./TemplateEditModal";

const Templates = ({ searchQuery }) => {
  const [meta, setMeta] = useState({});
  const [current, setCurrent] = useState(1);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTempId, setSelectedTempId] = useState("");
  const [editTempId, setEditTempId] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [updateStatus, setUpdateStatus] = useState(false); // New state variable for update status
  const { templateDetail, setTemplateDetail } = useContext(DataContext);

  const openModal = () => {
    setShowTemplateModal(true);
  };

  const openEditModal = async (templateId) => {
    setEditModal(true);
    setEditTempId(templateId);
  };

  const openDeleteModal = (templateId) => {
    console.log(templateId);
    setSelectedTempId(templateId);
    setShowDeleteModal(true);
  };

  const getTemplateData = async (pageNumber, query = "") => {
    let apiResponse;

    if (query !== "") {
      apiResponse = await findTemplate(pageNumber, query);
    } else {
      apiResponse = await getTemplateListing(pageNumber);
    }

    const { data } = apiResponse;
    const templateData = data.data || [];
    const meta = data.meta || {};

    console.log("templatedata", data);
    console.log(meta, "meta-template-data");

    setMeta(meta);
    setSearchResults(templateData);
  };

  const getTemplateInfo = async (id) => {
    const { data: detail } = await getTemplateDetail(id);
    console.log(detail, "template detail api");
    setTemplateDetail(detail?.data);
    openModal();
  };

  useEffect(() => {
    let getDataId;
    if (searchQuery !== "") {
      getDataId = setTimeout(() => {
        getTemplateData(current, searchQuery);
      }, 500);
    } else getTemplateData(current, searchQuery);

    return () => clearTimeout(getDataId);
  }, [current, searchQuery]);

  useEffect(() => {
    if (updateStatus) {
      getTemplateData(current, searchQuery);
      setUpdateStatus(false); // Reset the update status
    }
  }, [current, searchQuery, updateStatus]);

  return (
    <>
      {templateDetail && (
        <TemplateDetailDisplayModal
          showTemplateModal={showTemplateModal}
          setShowTemplateModal={setShowTemplateModal}
        />
      )}

      <TemplateEditModal
        templateId={editTempId}
        editModal={editModal}
        setEditModal={setEditModal}
        setUpdateStatus={setUpdateStatus}
      />

      <TemplateDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        templateId={selectedTempId}
        searchResults={setSearchResults}
        setUpdateStatus={setUpdateStatus}
      />

      <div className="template-window">
        <div className="template-head">
          <p className="tot-results">Showing {meta?.total_records} Results</p>
          <Link to="/add-template">
            <button className="add-btn" style={{ cursor: "pointer" }}>
              Add
            </button>
          </Link>
        </div>
        <div className="template-tab">
          <div className="template-tab-main">
            <div className="template-tab-main-left">
              {/* <input
                type="checkbox"
                className="template-checkbox"
                onChange={handleMainCheckboxChange}
              /> */}
              <p className="templates-heading">Templates</p>
            </div>
            <div className="template-tab-main-right">
              <div className="template-tab-main-right-start">
                <p className="age-group">Age Group</p>
              </div>
              <div className="template-tab-main-right-end">
                <p className="view-heading">View</p>
                <p className="edit-heading">Edit</p>
                <p className="delete-heading">Delete</p>
              </div>
            </div>
          </div>
          <hr className="templates-tab-hr" />
          <div className="template-tab-content">
            {searchResults.map((data, index) => (
              <div className="template1" key={index}>
                <div className="template-tab-content-left">
                  {/* <input type="checkbox" className="template-checkbox" /> */}
                  <p className="template-name">{data.name ?? ""}</p>
                </div>
                <div className="template-tab-content-right">
                  <div className="template-tab-content-right-start">
                    <p className="age-grp">{data?.suitableFor}</p>
                  </div>
                  <div className="template-tab-content-right-end">
                    <img
                      src={CategoryView}
                      alt=""
                      className="template-view"
                      style={{ cursor: "pointer" }}
                      onClick={() => getTemplateInfo(data._id)}
                    />
                    <img
                      src={Edit}
                      alt=""
                      className="template-edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => openEditModal(data._id)}
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={DeleteUser}
                      alt=""
                      className="template-delete"
                      onClick={() => openDeleteModal(data._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current - 1)}
              disabled={current === 1}
            >
              Previous
            </button>
            <p className="total-results">
              Showing Page {meta?.current_page} of {meta?.total_pages}
            </p>
            <button
              style={{ cursor: "pointer" }}
              className="pagination-btn"
              onClick={() => setCurrent(current + 1)}
              disabled={current === meta?.total_pages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Templates;
